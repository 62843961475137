import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image";

import SEO from '../../components/seo';
import Layout from '../../components/layout';
import Flex from '../../components/flex';
import CaseStudyNav from "../../components/caseStudyNav";
import CaseStudyOverview from "../../components/caseStudyOverview";
import Hr from "../../components/hr";
import Research from "../../components/research";
import Column from "../../components/column";
import CaseStudySection from "../../components/caseStudySection";

import userImage1 from '../../images/casestudy-pardee-image2.png';
import userImage2 from '../../images/casestudy-pardee-image3.png';
import userImage3 from '../../images/casestudy-pardee-image4.png';
import UserCard from "../../components/userCard";
import Quote from "../../components/quote";
import DesignSection from "../../components/designSection";

const HaltonPardee = ({
  uri,
}) => {
  const {
    banner: { childImageSharp: { fluid: banner } },
    taskAnalysis: { childImageSharp: { fluid: taskAnalysis } },
    tableFindings: { childImageSharp: { fluid: tableFindings } },
    findingsImage: { childImageSharp: { fluid: findingsImage } },
    userPersona: { childImageSharp: { fluid: userPersona } },
    wireFramePrototyping: { childImageSharp: { fluid: wireFramePrototyping } },
    design1: { childImageSharp: { fluid: design1 } },
    design2: { childImageSharp: { fluid: design2 } },
    design3: { childImageSharp: { fluid: design3 } },
  } = useStaticQuery(graphql`
    query {
      banner: file(relativePath: { eq: "pardee-banner.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      taskAnalysis: file(relativePath: { eq: "casestudy-pardee-image1.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tableFindings: file(relativePath: { eq: "casestudy-flexjobs-image2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      findingsImage: file(relativePath: { eq: "casestudy-flexjobs-image3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      userPersona: file(relativePath: { eq: "casestudy-pardee-image5.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      wireFramePrototyping: file(relativePath: { eq: "casestudy-pardee-image5.png" }) {
        childImageSharp {
          fluid(maxWidth: 900) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design1: file(relativePath: { eq: "casestudy-pardee-image6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design2: file(relativePath: { eq: "casestudy-flexjobs-image6.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      design3: file(relativePath: { eq: "casestudy-flexjobs-image7.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 560) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Pardee UX Case Study" />
      <Flex
        flexDirection="column"
        alignItems="center"
      >
        <Img fluid={banner} style={{ width: '100%', }} />
        <Flex
          flexDirection="column"
          alignItems="center"
          width={1}
          px={[20, null, 0]}
        >
          <CaseStudyNav uri={uri} design={false} />
          <CaseStudyOverview
            context="Module designs for a website overhaul, new UX"
            team="Myself, Product Manager, and Web Developer"
            role="In-house UX Researcher + Designer"
            timeline="6 week phase of 6 month project"
            deliverables="Research findings, user journey maps, personas, low fidelity wireframes and interactive prototypes of modules, written specifications for developer handoff"
            title="HALTON PARDEE + PARTNERS"
            type="A UX Case Study"
            description="Halton Pardee + Partners (haltonpardee.com) is a boutique real estate brokerage and the #1 Sales Team in Los Angeles by Sales Volume."
          />
          <Hr />
          <Research
            challenges={(
              <>
                <Flex>• Mobile users grew to +50% but design is not optimized.</Flex>
                <Flex>• Lack of internal development team means resources must be carefully allocated.</Flex>
                <Flex>• Diverse user base encompasses several segments.</Flex>
                <Flex>• Various user segments, in all phases need education about the real estate process.</Flex>
              </>
            )}
            solutions={(
              <div>
                Through task analysis and contextual inquiry, we discovered the <strong>tasks that are most important to users</strong> and cross-referenced with backend content management tasks. Using a <strong>mobile-first</strong> and <strong>atomic design approach</strong>, I created <strong>flexible, re-usable modules</strong> for publishing content.
              </div>
            )}
            competitors={(
              <>
                <Column>
                  <Flex>• sherrinoel.com</Flex>
                  <Flex>• jesseweinberg.com</Flex>
                  <Flex>• masercondosales.com</Flex>
                  <Flex>• tiffanyrochellegroup.com</Flex>
                </Column>
                <Column>
                  <Flex>• theagencyre.com</Flex>
                  <Flex>• stephanieyounger.com</Flex>
                  <Flex>• sallyforsterjones.com</Flex>
                  <Flex>• plgestates.com</Flex>
                </Column>
                <Column last>
                  <Flex>• redfin.com</Flex>
                  <Flex>• zillow.com (as a platform)</Flex>
                  <Flex>• trulia.com (as a platform)</Flex>
                  <Flex>• realtor.com (as a platform)</Flex>
                </Column>
              </>
            )}
          />
          <CaseStudySection>
            <Img fluid={taskAnalysis} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
          <DesignSection
            image={design1}
            imageFirst={false}
          >
            <h4>User Research</h4>
            <p>
              Working backward from the services the company offers and <strong>business goals</strong>, I planned how to <strong>research and reach users</strong> in need of those services and doing their own research into it.
            </p>
            <p>
              I utilized existing data from <strong>Zillow</strong> and <strong>Realtor associations</strong> to find out more about the <strong>behaviors and needs</strong> of home buyers and sellers. 
            </p>
            <p>
              I <strong>interviewed my Realtor colleagues</strong> — both seller and buyer specialists about <strong>their own needs</strong> as well as <strong>insight into common client behaviors and general needs.</strong> 
            </p>
          </DesignSection>
          <CaseStudySection
            alignItems="center"
          >
            <h4>Insights</h4>
            <Quote
              quote="“91 percent of home purchases are influenced by women, according to the Harvard Business Review. Additionally, the National Association of REALTORS® 2018 Profile of Home Buyers and Sellers found that the number of single female buyers continues to rise, and are currently the second largest buyer group at 18 percent behind married couples at 63 percent.”"
              attribute="- Realtor Magazine"
            />
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="empathize">Empathize</h2>
            <h4>Users</h4>
            <Flex
              flexDirection={['column', null, 'row']}
            >
              <UserCard
                image={userImage1}
                header="Home Buyers — potential (future) and serious (now)"
              >
                <div>• Older millenial generation</div>
                <div>• Thorough with online research, constantly searching</div>
                <div>• Wants to independently learn about process, market & homes before committing to service/agent</div>
              </UserCard>
              <UserCard
                image={userImage2}
                header="Homeowners — potential (future) sellers and serious (now) sellers"
              >
                <div>• GenX to Baby boomer generations</div>
                <div>• Changing lifestyle/circumstances lead to selling</div>
                <div>• Value full personalized service and education by an agent</div>
                <div>• Prefer in person or phone calls</div>
              </UserCard>
              <UserCard
                image={userImage3}
                header="Real Estate Professionals"
                last
              >
                <div>• Desire quick and straightforward property and contact information</div>
                <div>• Busy professionals, scanning web on behalf of their clients</div>
                <div>• Looking for exclusive listings</div>
                <div>• Tend to understand the areas in which other professionals and brokerages specialize</div>
              </UserCard>
            </Flex>
          </CaseStudySection>
          <Hr />
          <CaseStudySection>
            <h2 name="ideate">Ideate</h2>
            <h4>Potential solutions for the flexible modules were quickly mocked up</h4>
          </CaseStudySection>
          <CaseStudySection>
            <Img fluid={wireFramePrototyping} style={{ width: '100%', maxWidth: '900px', }} />
          </CaseStudySection>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default HaltonPardee;
