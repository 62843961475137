import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { space, layout } from 'styled-system'
import Flex from './flex';
import CaseStudySection from './caseStudySection';


const StyledHighlights = styled(Flex)`
  line-height: 1em;

  h4 {
    margin: 0;
    ${({ theme }) => layout({
      theme,
      width: [null, null, '130px']
    })}
    ${({ theme }) => space({
      theme,
      mb: [2, null, 0]
    })}
    text-align: right;
    font-style: normal;
    font-weight: normal;
    font-size: 21px;
    line-height: 1em;

    text-align: right;
    letter-spacing: 0.02em;
    margin-right: 20px;
  }
  div {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
  }
`;

StyledHighlights.defaultProps = {
  flexDirection: ['column', null, 'row'],
  alignItems: 'flex-start',
  my: 2,
};

const CaseStudyOverview = ({
  context,
  team,
  role,
  timeline,
  deliverables,
  title,
  type,
  description,
}) => {
  return (
    <CaseStudySection
      flexDirection={['column', null, 'row']}
    >
      <Flex
        flexDirection="column"
        bg="highlight"
        maxWidth={560}
        mr={[null, null, 44]}
        py={40}
        px={15}
      >
        <StyledHighlights>
          <h4>Context:</h4>
          <div>{context}</div>
        </StyledHighlights>
        <StyledHighlights>
          <h4>Team:</h4>
          <div>{team}</div>
        </StyledHighlights>
        <StyledHighlights>
          <h4>Role:</h4>
          <div>{role}</div>
        </StyledHighlights>
        <StyledHighlights>
          <h4>Timeline:</h4>
          <div>{timeline}</div>
        </StyledHighlights>
        <StyledHighlights>
          <h4>Deliverables:</h4>
          <div>{deliverables}</div>
        </StyledHighlights>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="flex-start"
        maxWidth={354}
        order={[-1, null, 1]}
      >
        <h1>{title}</h1>
        <h2>{type}</h2>
        <p>{description}</p>
      </Flex>
    </CaseStudySection>
  );
};

CaseStudyOverview.propTypes = {
  context: PropTypes.string,
  team: PropTypes.string,
  role: PropTypes.string,
  timeline: PropTypes.string,
  deliverables: PropTypes.string,
  title: PropTypes.string,
  type: PropTypes.string,
  description: PropTypes.string,
};

export default CaseStudyOverview;
