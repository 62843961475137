import React from 'react';
import PropTypes from 'prop-types';
import Flex from './flex';
import CaseStudySection from './caseStudySection';

const Research = ({
  challenges,
  solutions,
  competitors,
}) => {
  return (
    <>
      <CaseStudySection>
        <Flex
          flexDirection="column"
          alignItems="flex-start"
        >
          <h2 name="research">Research</h2>
          <Flex
            flexDirection={['column', null, 'row']}
          >
            <Flex
              flexDirection="column"
              flex="1"
              mr={[null, null, 4]}
              lineHeight="215%"
            >
              <h4>Challenges</h4>
              {challenges}
            </Flex>
            <Flex
              flexDirection="column"
              flex="1"
            >
              <h4>Solutions</h4>
              {solutions}
            </Flex>
          </Flex>
        </Flex>
      </CaseStudySection>
      {
        competitors &&
        <CaseStudySection>
          <h4>Competitors</h4>
          <Flex
            flexDirection={['column', null, 'row']}
          >
            {competitors}
          </Flex>
        </CaseStudySection>
      }
    </>
  );
};

Research.propTypes = {
  challenges: PropTypes.node,
  solutions: PropTypes.node,
  competitors: PropTypes.node,
}

export default Research;
