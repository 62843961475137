import React from 'react';
import PropTypes from 'prop-types';
import Flex from './flex';

const Quote = ({
  quote,
  attribute,
}) => {
  return (
    <>
      <Flex
        fontStyle="italic"
        textAlign="center"
        width={['100%', null, 500, 750]}
      >
        {quote}
      </Flex>
      <Flex
        fontWeight="bold"
        mt={2}
      >
        {attribute}
      </Flex>
    </>
  );
};

Quote.propTypes = {
  quote: PropTypes.string,
  attribute: PropTypes.string,
};

export default Quote;
