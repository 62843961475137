import React from 'react';
import Flex from './flex';

const Section = (props) => {
  return (
    <Flex
      flexDirection="column"
      my={4}
      px={[4, null, null, 0]}
      maxWidth={900}
      mx={3}
      width="100%"
      fontStyle="normal"
      fontWeight="300"
      fontSize="16px"
      lineHeight="140%"
      letterSpacing="0.02em"
      {...props}
    />
  );
};

export default Section;
