import React from 'react';
import PropTypes from 'prop-types';
import Flex from './flex';
import Img from './img';

const UserCard = ({
  image,
  header,
  children,
  last,
  ...props
}) => {
  return (
    <Flex
      flexDirection="column"
      width="260px"
      mb={[3, null, 0]}
      {...(!last ? {
        mr: 4,
      } : {})}
      {...props}
    >
      <Img
        src={image}
        width="260px"
        height="200px"
      />
      <Flex
        fontWeight="bold"
        mb={2}
      >
        {header}
      </Flex>
      <div>
        {children}
      </div>
    </Flex>
  );
};

UserCard.propTypes = {
  image: PropTypes.string,
  header: PropTypes.string,
  children: PropTypes.node,
  last: PropTypes.bool,
};

export default UserCard;
