import React from 'react';
import PropTypes from 'prop-types';
import Flex from './flex';

const Column = ({ last, ...props}) => {
  return (
    <Flex
      flex="1"
      flexDirection="column"
      {...(!last ? {
        mr: 4,
      } : {})}
      {...props}
    />
  );
};

Column.propTypes = {
  last: PropTypes.bool,
};

export default Column;
