import React from 'react';
import PropTypes from 'prop-types';
import Img from "gatsby-image";
import styled from 'styled-components';

import CaseStudySection from './caseStudySection';
import Column from './column';
import Flex from './flex';

const StyledColumn = styled(Column)`
  p {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 140%;
  }
`;

const DesignSection = ({
  image,
  imageFirst,
  children,
}) => {
  const column1Props = {};
  const column2Props = {};

  const imageContent = (
    <Flex
      width={['100%', null, "560px"]}
    >
      <Img fluid={image} style={{ width: '100%', maxWidth: '560px', }} />
    </Flex>
  );

  const content = imageFirst ? imageContent : children;
  const content2 = imageFirst ? children: imageContent;

  return (
    <CaseStudySection>
      <Flex
        flexDirection={['column', null, 'row']}
      >
        <StyledColumn
          {...column1Props}
        >
          {content}
        </StyledColumn>
        <StyledColumn
          last
          {...column2Props}
        >
          {content2}
        </StyledColumn>
      </Flex>
    </CaseStudySection>
  );
};

DesignSection.propTypes = {
  image: PropTypes.object,
  children: PropTypes.node,
  imageFirst: PropTypes.bool,
};

export default DesignSection;
