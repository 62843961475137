import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

import Flex from './flex';
import BaseLink from './link';



const Link = styled(BaseLink)`
  text-decoration: none;

  &.active {
    border-bottom: 2px solid;
    border-color: ${themeGet('colors.secondary')};
  }
`;

Link.defaultProps = {
  color: 'text',
  activeClassName: 'active',
  fontFamily: 'sansSerif',
  fontWeight: 'bold',
  fontSize: '14px',
  lineHeight: '16px',
  letterSpacing: '0.02em',
  px: 8,
  mx: 16,
};

const CaseStudyNav = ({
  uri,
  research,
  empathize,
  ideate,
  design,
}) => {
  return (
    <Flex
      width="100%"
      borderBottom="1px solid"
      borderColor="primary"
      height={80}
      alignItems="center"
      flexWrap="wrap"
    >
      <Link to={`${uri}`}>Overview</Link>
      {research && <Link to={`${uri}#research`}>Research</Link>}
      {empathize && <Link to={`${uri}#empathize`}>Empathize</Link>}
      {ideate && <Link to={`${uri}#ideate`}>Ideate</Link>}
      {design && <Link to={`${uri}#design`}>Design</Link>}
    </Flex>
  );
};

CaseStudyNav.propTypes = {
  uri: PropTypes.string,
  research: PropTypes.bool,
  empathize: PropTypes.bool,
  ideate: PropTypes.bool,
  design: PropTypes.bool,
};

CaseStudyNav.defaultProps = {
  research: true,
  empathize: true,
  ideate: true,
  design: true,
}

export default CaseStudyNav;
